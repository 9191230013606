<template>
  <ContentCard v-if="!edit" :contentObject="getDisplayContent" :onClick="disableEdit ? undefined : () => edit = true"/>
  <div v-else>
    <EditComponent
      class="exporo-app-edit-view"
      v-bind:limitedFields="limitedFields"
      v-bind:title="$t('metaData.fields.naturalUser.address.label')"
      v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, onCancel, nextBtnClicked }"
      :stateCallback="handleEditState"
    >
      <InputComponent
        v-model="naturalUser.firstName"
        :label="$t('metaData.fields.naturalUser.firstName.label')"
        :placeholder="$t('metaData.fields.naturalUser.firstName.label')"
        :error="checkIfExists('errors.naturalUser.firstName') ? niceErrorMessage(errors.naturalUser.firstName) : ''"
        :tooltip="isIdentified && !disableTooltip ? $t('metaData.fields.naturalUser.firstName.tooltip') : null"
        :nextBtnClicked="nextBtnClicked"
        inputType="text"
        :disabled="isIdentified"
        :filled="true"
      />
      <InputComponent
        v-model="naturalUser.lastName"
        :label="$t('metaData.fields.naturalUser.lastName.label')"
        :placeholder="$t('metaData.fields.naturalUser.lastName.placeholder')"
        :error="checkIfExists('errors.naturalUser.lastName') ? niceErrorMessage(errors.naturalUser.lastName) : ''"
        :tooltip="isIdentified && !disableTooltip ? $t('metaData.fields.naturalUser.lastName.tooltip') : null"
        :nextBtnClicked="nextBtnClicked"
        inputType="text"
        :disabled="isIdentified"
        :filled="true"
      />
      <SelectComponent
        v-model="naturalUser.title"
        :label="$t('metaData.fields.naturalUser.title.label')"
        :optionsList="titleList"
        :placeholder="$t('metaData.fields.naturalUser.title.placeholder')"
        :error="checkIfExists('errors.naturalUser.title') ? niceErrorMessage(errors.naturalUser.title) : ''"
        :nextBtnClicked="nextBtnClicked"
        valueKey="value"
        valueLabel="text"
        clearable
        :filled="true"
      />
      <SelectComponent
        v-model="naturalUser.sex"
        :label="$t('metaData.fields.naturalUser.sex.label')"
        :optionsList="sexList"
        :placeholder="$t('metaData.fields.naturalUser.sex.placeholder')"
        :error="checkIfExists('errors.naturalUser.sex') ? niceErrorMessage(errors.naturalUser.sex) : ''"
        :nextBtnClicked="nextBtnClicked"
        valueKey="value"
        valueLabel="sex"
        :filled="true"
      />
      <SelectComponent
        v-model="naturalUser.citizenship"
        :label="$t('metaData.fields.naturalUser.citizenship.label')"
        :optionsList="nationalityOptions"
        :error="
          checkIfExists('errors.naturalUser.citizenship') ? niceErrorMessage(errors.naturalUser.citizenship) : ''
        "
        :nextBtnClicked="nextBtnClicked"
        valueKey="code"
        valueLabel="country"
        :filled="true"
      />
      <FormButtons :onSave="onSave" :onCancel="onCancel" />
    </EditComponent>
  </div>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import SelectComponent from '@/components/reusable/Select/Select.vue';
import FormButtons from '@/components/ExporoApp/reusable/FormButtons.vue';
import ContentCard from '@/components/ExporoApp/reusable/ContentCard.vue';
import { nationalityOptions } from '@/enum/countries';
import { sexList, titleList } from '@/enum/userOptions';

export default {
  name: 'GeneralCard',
  data() {
    return {
      nationalityOptions: nationalityOptions().map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      })),
      sexList,
      titleList,
      edit: false,
    };
  },
  methods: {
    handleEditState(loadingState, errors) {
      this.loading = loadingState;
      if(errors.length === 0 && !this.loading) {
        this.edit = false;
      }
    },
  },
  computed: {
    isIdentified: function () {
      // if identification is made basic info data will be overwritten by data from the ident service
      return this.$store.getters.isIdentified;
    },
    getDisplayContent() {
      return {
        firstName: {
          label: this.$t('metaData.fields.naturalUser.firstName.label'),
          value: this.$store.state.naturalUser.firstName,
          placeholder: this.$t('metaData.fields.naturalUser.firstName.noValue'),
        },
        lastName: {
          label: this.$t('metaData.fields.naturalUser.lastName.label'),
          value: this.$store.state.naturalUser.lastName,
          placeholder: this.$t('metaData.fields.naturalUser.lastName.noValue'),
        },
        title: {
          label: this.$t('metaData.fields.naturalUser.title.label'),
          value: this.$store.state.naturalUser.title 
            ? this.titleList.filter(opt => opt.value === this.$store.state.naturalUser.title)[0].text
            : null,
        },
        sex: {
          label: this.$t('metaData.fields.naturalUser.sex.label'),
          value: this.$store.state.naturalUser.sex
            ? this.sexList.filter(opt => opt.value === this.$store.state.naturalUser.sex)[0].text 
            : null,
          placeholder: this.$t('metaData.fields.naturalUser.sex.noValue'),
        },
        citizenship: {
          label: this.$t('metaData.fields.naturalUser.citizenship.label'),
          value: this.$store.state.naturalUser.citizenship
            ? this.nationalityOptions.filter(opt => opt.value === this.$store.state.naturalUser.citizenship)[0].text
            : null,
          placeholder: this.$t('metaData.fields.naturalUser.citizenship.noValue'),
        },
      };
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'generalInformation',
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    disableTooltip: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ContentCard,
    FormButtons,
    EditComponent,
    InputComponent,
    SelectComponent,
  },
};
</script>

<style scoped lang="scss">

</style>

