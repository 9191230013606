var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.edit)?_c('ContentCard',{attrs:{"contentObject":_vm.getDisplayContent,"onClick":function () { return _vm.edit = true; },"loading":_vm.loading}}):_c('EditComponent',{staticClass:"exporo-app-edit-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.address.label'),"stateCallback":_vm.handleEditState},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var errors = ref.errors;
var onSave = ref.onSave;
var onCancel = ref.onCancel;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.address.street.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.street.placeholder'),"error":checkIfExists('errors.naturalUser.address.street')
        ? niceErrorMessage(errors.naturalUser.address.street)
        : '',"nextBtnClicked":nextBtnClicked,"inputType":"text","filled":true},model:{value:(naturalUser.address.street),callback:function ($$v) {_vm.$set(naturalUser.address, "street", $$v)},expression:"naturalUser.address.street"}}),_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.address.streetNo.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.streetNo.label'),"error":checkIfExists('errors.naturalUser.address.streetNo')
        ? niceErrorMessage(errors.naturalUser.address.streetNo)
        : '',"nextBtnClicked":nextBtnClicked,"inputType":"text","filled":true},model:{value:(naturalUser.address.streetNo),callback:function ($$v) {_vm.$set(naturalUser.address, "streetNo", $$v)},expression:"naturalUser.address.streetNo"}}),_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.address.streetAdditional.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.streetAdditional.placeholder'),"error":checkIfExists('errors.naturalUser.address.streetAdditional')
        ? niceErrorMessage(errors.naturalUser.address.streetAdditional)
        : '',"nextBtnClicked":nextBtnClicked,"inputType":"text","filled":true},model:{value:(naturalUser.address.streetAdditional),callback:function ($$v) {_vm.$set(naturalUser.address, "streetAdditional", $$v)},expression:"naturalUser.address.streetAdditional"}}),_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.address.postalCode.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.postalCode.placeholder'),"error":checkIfExists('errors.naturalUser.address.postalCode')
        ? niceErrorMessage(errors.naturalUser.address.postalCode)
        : '',"nextBtnClicked":nextBtnClicked,"inputType":"text","filled":true},model:{value:(naturalUser.address.postalCode),callback:function ($$v) {_vm.$set(naturalUser.address, "postalCode", $$v)},expression:"naturalUser.address.postalCode"}}),_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.address.city.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.city.placeholder'),"error":checkIfExists('errors.naturalUser.address.city') ? niceErrorMessage(errors.naturalUser.address.city) : '',"nextBtnClicked":nextBtnClicked,"inputType":"text","filled":true},model:{value:(naturalUser.address.city),callback:function ($$v) {_vm.$set(naturalUser.address, "city", $$v)},expression:"naturalUser.address.city"}}),_c('AutoCompleteComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.address.country.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.address.country.label'),"optionsList":_vm.countryOptions,"error":checkIfExists('errors.naturalUser.address.country')
        ? niceErrorMessage(errors.naturalUser.address.country)
        : '',"nextBtnClicked":nextBtnClicked,"valueKey":"code","valueLabel":"country","filled":true,"clearable":false},model:{value:(naturalUser.address.country),callback:function ($$v) {_vm.$set(naturalUser.address, "country", $$v)},expression:"naturalUser.address.country"}}),_c('FormButtons',{attrs:{"onSave":onSave,"onCancel":onCancel}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }