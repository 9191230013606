<template>
  <div class="profile-webview mb-12 pa-4" v-if="!loading">
    <div v-for="(section, index) in Object.keys(sections)" :key="index" :class="index === 0 ? 'section' : 'section mt-8'">
      <div class="section-label">
        {{ sections[section].label }}
      </div>
      <component :disable-edit="!!sections[section].disableEdit" :is="section" />
    </div>
  </div>
</template>


<script>
import * as _ from 'lodash';
import { mapGetters } from 'vuex';
import ContactInfoCard from '@/components/ExporoApp/ContactInfoCard.vue';
import GeneralCard from '@/components/ExporoApp/GeneralCard.vue';
import AddressCard from '@/components/ExporoApp/AddressCard.vue';
import BirthCard from '@/components/ExporoApp/BirthCard.vue';
import RewardsCard from '@/components/ExporoApp/RewardsCard.vue';
import AdditionalCard from '@/components/ExporoApp/AdditionalCard.vue';

export default {
  components: {
    AddressCard,
    BirthCard,
    ContactInfoCard,
    GeneralCard,
    RewardsCard,
    AdditionalCard,
  },
  data() {
    return {
      naturalUser: {},
      loading: true,
      sections: {
        'ContactInfoCard': {
          label: this.$t('profile.contactInformation').toUpperCase(),
        },
        'GeneralCard': {
          label: this.$t('metaData.fields.naturalUser.label').toUpperCase(),
          disableEdit: true,
        },
        'AddressCard': {
          label: this.$t('metaData.fields.naturalUser.address.label').toUpperCase(),
        },
        'BirthCard': {
          label: this.$t('metaData.fields.naturalUser.birth.label').toUpperCase(),
        },
        'RewardsCard': {
          label: this.$t('menu.rewardsInformation').toUpperCase(),
        },
        'AdditionalCard': {
          label: this.$t('metaData.fields.naturalUser.additionalInformation').toUpperCase(),
        },
      },
    };
  },
  async mounted() {
    this.$store.commit('setLoaded', { loaded: true });

    this.loading = false;
  },

  computed: {
    ...mapGetters(['isIdentified']),
  },
};
</script>

<style scoped lang="scss">
@import '@/style/media';

.profile-webview {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: -webkit-fill-available;
}
.section-label {
  height: 32px;
  color: #717E8E;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  padding-bottom: 16px;
}
</style>