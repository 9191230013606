<template>
  <v-card class="px-4 pt-6 pb-2" :loading="loading">
    <v-btn v-if="onClick" icon color="primary" absolute top right :small="$vuetify.breakpoint.xs" @click="() => onClick()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33366 13.6785V16.6667H6.32181L16.3218 6.66669L13.3337 3.67853L3.33366 13.6785ZM13.9229 1.91076L18.0896 6.07743C18.415 6.40287 18.415 6.93051 18.0896 7.25594L7.25625 18.0893C7.09997 18.2456 6.88801 18.3334 6.66699 18.3334H2.50033C2.04009 18.3334 1.66699 17.9603 1.66699 17.5V13.3334C1.66699 13.1123 1.75479 12.9004 1.91107 12.7441L12.7444 1.91076C13.0698 1.58533 13.5975 1.58533 13.9229 1.91076Z" fill="#1F4B7A"/>
      </svg>
    </v-btn>
    <template v-for="(key, index) in Object.keys(contentObject)">
      <div v-if="contentObject[key].value || contentObject[key].placeholder" class="content pb-4" :key="index">
        <div class="display-label pb-2"> {{ contentObject[key].label }}</div>
        <div v-if="contentObject[key].value" class="display-value" >{{ contentObject[key].value }}</div>
        <div v-else-if="contentObject[key].placeholder" class="display-placeholder" >{{ contentObject[key].placeholder }}</div>
      </div>
    </template>
  </v-card>
</template>

<script>

export default {
  name: 'ContentCard',
  props: {
    contentObject: {
      type: Object,
      default: () => {
        return {
          section: {
            label: 'asdf',
            value: 'foobar',
            placeholder: 'foobar',
          },
        };
      },
    },
    onClick: {
      type: Function,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">

  .v-card {
    width: 480px;
    height: 100% !important;
    z-index: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 32px 0px #170E250D, 0px 0px 4px 0px #170E250A !important;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .display-label {
    color: #A9B2BB !important;
    margin-bottom: 0px !important;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1000000015px;
    text-align: left;
  }
  .display-value {
    color: #1F4B7A !important;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1000000015px;
    text-align: left;
  }
  .display-placeholder {
    color: #717E8E !important;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1000000015px;
    text-align: left;
  }
</style>

