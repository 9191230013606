<template>
  <ContentCard v-if="!edit" :contentObject="getDisplayContent" :onClick="() => edit = true" />
  <div v-else>
    <EditComponent
      class="exporo-app-edit-view"
      v-bind:limitedFields="limitedFields"
      v-bind:title="$t('metaData.fields.naturalUser.label')"
      v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, onCancel, nextBtnClicked }"
      :stateCallback="handleEditState"
    >
      <InputComponent
        v-model="naturalUser.phoneMobile"
        :label="$t('metaData.fields.naturalUser.phoneMobile.label')"
        :placeholder="$t('metaData.fields.naturalUser.phoneMobile.label')"
        :error="
          checkIfExists('errors.naturalUser.phoneMobile') ? niceErrorMessage(errors.naturalUser.phoneMobile) : ''
        "
        :nextBtnClicked="nextBtnClicked"
        inputType="text"
        :filled="true"
      />
      <EditEmail :filled="true"/>
      <InputComponent
        v-model="naturalUser.phone"
        :label="$t('metaData.fields.naturalUser.phone.label')"
        :placeholder="$t('metaData.fields.naturalUser.phone.label')"
        :error="checkIfExists('errors.naturalUser.phone') ? niceErrorMessage(errors.naturalUser.phone) : ''"
        :nextBtnClicked="nextBtnClicked"
        inputType="text"
        :filled="true"
      />
      <FormButtons :onSave="onSave" :onCancel="onCancel" />
    </EditComponent>
  </div>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import EditEmail from '@/components/reusable/Input/EditEmail';
import InputComponent from '@/components/reusable/Input/Input.vue';
import FormButtons from '@/components/ExporoApp/reusable/FormButtons.vue';
import ContentCard from '@/components/ExporoApp/reusable/ContentCard.vue';

export default {
  name: 'ContactInfoCard',
  data() {
    return {
      edit: false,
    };
  },
  computed: {
    getDisplayContent() {
      return {
        phoneMobile: {
          label: this.$t('metaData.fields.naturalUser.phoneMobile.label'),
          value: this.$store.state.naturalUser.phoneMobile,
          placeholder: this.$t('metaData.fields.naturalUser.phoneMobile.noValue'),
        },
        userEmail: {
          label: this.$t('user.amplify.Email'),
          value: this.$store.state.user.email,
          placeholder: this.$t('metaData.fields.naturalUser.email.noValue'),
        },
        phone: {
          label: this.$t('metaData.fields.naturalUser.phone.label'),
          value: this.$store.state.naturalUser.phone,
          placeholder: this.$t('metaData.fields.naturalUser.phone.noValue'),
        },
      };
    },
  },
  methods: {
    handleEditState(loadingState, errors) {
      this.loading = loadingState;
      if(errors.length === 0 && !this.loading) {
        this.edit = false;
      }
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'address',
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContentCard,
    FormButtons,
    EditEmail,
    EditComponent,
    InputComponent,
  },
};
</script>

<style scoped lang="scss">

</style>

