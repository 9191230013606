var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.edit)?_c('ContentCard',{attrs:{"contentObject":_vm.getDisplayContent,"onClick":_vm.disableEdit ? undefined : function () { return _vm.edit = true; }}}):_c('div',[_c('EditComponent',{staticClass:"exporo-app-edit-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.address.label'),"stateCallback":_vm.handleEditState},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var onCancel = ref.onCancel;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('SelectComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.maritalStatus.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.maritalStatus.label'),"optionsList":_vm.statusList,"error":checkIfExists('errors.naturalUser.maritalStatus') ? niceErrorMessage(errors.naturalUser.maritalStatus) : '',"nextBtnClicked":nextBtnClicked,"valueKey":"value","valueLabel":"label","filled":true},model:{value:(naturalUser.maritalStatus),callback:function ($$v) {_vm.$set(naturalUser, "maritalStatus", $$v)},expression:"naturalUser.maritalStatus"}}),_c('SelectComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.job.group.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.job.group.label'),"optionsList":_vm.jobList,"error":checkIfExists('errors.naturalUser.job.group') ? niceErrorMessage(errors.naturalUser.job.group) : '',"nextBtnClicked":nextBtnClicked,"valueKey":"value","valueLabel":"job","filled":true},model:{value:(naturalUser.job.group),callback:function ($$v) {_vm.$set(naturalUser.job, "group", $$v)},expression:"naturalUser.job.group"}}),(naturalUser.job.group == 'selfEmployed')?_c('SelectComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.job.selfEmployedSector.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.job.selfEmployedSector.label'),"optionsList":_vm.branchList,"error":checkIfExists('errors.naturalUser.job.selfEmployedSector')
          ? niceErrorMessage(errors.naturalUser.job.selfEmployedSector)
          : '',"valueKey":"value","nextBtnClicked":nextBtnClicked,"valueLabel":"text","filled":true},model:{value:(naturalUser.job.selfEmployedSector),callback:function ($$v) {_vm.$set(naturalUser.job, "selfEmployedSector", $$v)},expression:"naturalUser.job.selfEmployedSector"}}):_vm._e(),_c('FormButtons',{attrs:{"onSave":onSave,"onCancel":onCancel}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }