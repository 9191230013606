<template>
  <ContentCard v-if="!edit" :contentObject="getDisplayContent" :onClick="disableEdit ? undefined : () => edit = true"/>
  <div v-else>
    <EditComponent
      class="exporo-app-edit-view"
      v-bind:limitedFields="limitedFields"
      v-bind:title="$t('metaData.fields.naturalUser.address.label')"
      v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, onCancel, nextBtnClicked }"
      :stateCallback="handleEditState"
    >
      <SelectComponent
        v-model="naturalUser.maritalStatus"
        :label="$t('metaData.fields.naturalUser.maritalStatus.label')"
        :placeholder="$t('metaData.fields.naturalUser.maritalStatus.label')"
        :optionsList="statusList"
        :error="
          checkIfExists('errors.naturalUser.maritalStatus') ? niceErrorMessage(errors.naturalUser.maritalStatus) : ''
        "
        :nextBtnClicked="nextBtnClicked"
        valueKey="value"
        valueLabel="label"
        :filled="true"
      />
      <SelectComponent
        v-model="naturalUser.job.group"
        :label="$t('metaData.fields.naturalUser.job.group.label')"
        :placeholder="$t('metaData.fields.naturalUser.job.group.label')"
        :optionsList="jobList"
        :error="checkIfExists('errors.naturalUser.job.group') ? niceErrorMessage(errors.naturalUser.job.group) : ''"
        :nextBtnClicked="nextBtnClicked"
        valueKey="value"
        valueLabel="job"
        :filled="true"
      />
      <SelectComponent
        v-if="naturalUser.job.group == 'selfEmployed'"
        v-model="naturalUser.job.selfEmployedSector"
        :label="$t('metaData.fields.naturalUser.job.selfEmployedSector.label')"
        :placeholder="$t('metaData.fields.naturalUser.job.selfEmployedSector.label')"
        :optionsList="branchList"
        :error="
          checkIfExists('errors.naturalUser.job.selfEmployedSector')
            ? niceErrorMessage(errors.naturalUser.job.selfEmployedSector)
            : ''
        "
        valueKey="value"
        :nextBtnClicked="nextBtnClicked"
        valueLabel="text"
        :filled="true"
      />
      <FormButtons :onSave="onSave" :onCancel="onCancel" />
    </EditComponent>
  </div>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import SelectComponent from '@/components/reusable/Select/Select.vue';
import FormButtons from '@/components/ExporoApp/reusable/FormButtons.vue';
import ContentCard from '@/components/ExporoApp/reusable/ContentCard.vue';

export default {
  name: 'AdditionalCard',
  data() {
    return {
      edit: false,
      jobList: [
        { value: 'employee', text: this.$t('employmentStatus.industry.employee') },
        { value: 'selfEmployed', text: this.$t('employmentStatus.industry.selfEmployed') },
        { value: 'publicOfficial', text: this.$t('employmentStatus.industry.publicOfficial') },
        { value: 'student', text: this.$t('employmentStatus.industry.student') },
        { value: 'privateer', text: this.$t('employmentStatus.industry.privateer') },
        { value: 'pensioner', text: this.$t('employmentStatus.industry.pensioner') },
      ],
      branchList: [
        { value: '01000', text: this.$t('selfEmployedSector.industry.agriculture') },
        { value: '05100', text: this.$t('selfEmployedSector.industry.mining') },
        { value: '23000', text: this.$t('selfEmployedSector.industry.glassware') },
        { value: '29000', text: this.$t('selfEmployedSector.industry.motorVehicleIndustry') },
        { value: '35000', text: this.$t('selfEmployedSector.industry.energySupply') },
        { value: '36000', text: this.$t('selfEmployedSector.industry.waterSupplyDisposal') },
        { value: '41000', text: this.$t('selfEmployedSector.industry.buildingConstruction') },
        { value: '45000', text: this.$t('selfEmployedSector.industry.tradeMotorVehicle') },
        { value: '47000', text: this.$t('selfEmployedSector.industry.retailTrade') },
        { value: '52000', text: this.$t('selfEmployedSector.industry.transportServices') },
        { value: '56000', text: this.$t('selfEmployedSector.industry.gastronomy') },
        { value: '63000', text: this.$t('selfEmployedSector.industry.informationServices') },
        { value: '68000', text: this.$t('selfEmployedSector.industry.realEstateHousing') },
        { value: '74000', text: this.$t('selfEmployedSector.industry.freelanceScientificActivities') },
        { value: '82000', text: this.$t('selfEmployedSector.industry.economicServices') },
        { value: '84000', text: this.$t('selfEmployedSector.industry.publicAdministration') },
        { value: '85000', text: this.$t('selfEmployedSector.industry.educationTeaching') },
        { value: '86000', text: this.$t('selfEmployedSector.industry.healthCare') },
        { value: '90000', text: this.$t('selfEmployedSector.industry.artisticActivities') },
        { value: '93200', text: this.$t('selfEmployedSector.industry.serviceSportEntertainment') },
      ],
      statusList: [
        { value: 'single', text: this.$t('metaData.fields.naturalUser.maritalStatus.options.single') },
        { value: 'married', text: this.$t('metaData.fields.naturalUser.maritalStatus.options.married') },
        {
          value: 'civil_partnership',
          text: this.$t('metaData.fields.naturalUser.maritalStatus.options.civilPartnership'),
        },
        { value: 'divorced', text: this.$t('metaData.fields.naturalUser.maritalStatus.options.divorced') },
      ],
    };
  },
  methods: {
    handleEditState(loadingState, errors) {
      this.loading = loadingState;
      if(errors.length === 0 && !this.loading) {
        this.edit = false;
      }
    },
  },
  computed: {
    getDisplayContent() {
      return {
        maritalStatus: {
          label: this.$t('metaData.fields.naturalUser.maritalStatus.label'),
          value: this.$store.state.naturalUser.maritalStatus 
            ? this.statusList.filter(opt => opt.value === this.$store.state.naturalUser.maritalStatus)[0].text
            : this.$t('metaData.fields.naturalUser.maritalStatus.label'),
        },
        jobGroup: {
          label: this.$t('metaData.fields.naturalUser.job.group.label'),
          value: this.$store.state.naturalUser.job.group 
            ? this.jobList.filter(opt => opt.value === this.$store.state.naturalUser.job.group)[0].text
            : this.$t('metaData.fields.naturalUser.job.group.label'),
        },
        ...this.getBranche,
      };
    },
    getBranche() {
      return this.$store.state.naturalUser.job.group === 'selfEmployed'
          ? {
              selfEmployedSector: {
                label: this.$t('metaData.fields.naturalUser.job.selfEmployedSector.label'),
                value: this.branchList.filter(opt => opt.value === this.$store.state.naturalUser.job.selfEmployedSector)[0].text
                  || this.$t('metaData.fields.naturalUser.selfEmployedSector.label'),
              },
            }
          : undefined;
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'additionalInformationCard',
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContentCard,
    FormButtons,
    EditComponent,
    SelectComponent,
  },
};
</script>

<style scoped lang="scss">

</style>

