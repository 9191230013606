<template>
  <div class="form-buttons"> 
    <div class="form-button pr-1"> 
      <v-btn
        color="primary"
        block
        rounded
        outlined
        :disabled="disabled"
        @click.prevent="handleCancel(onCancel)"
      >
        {{ $t('cancelBtn') }}
      </v-btn>
    </div>
    <div class="form-button pl-1">
      <v-btn
        color="primary"
        block
        rounded
        :disabled="disabled"
        @click.prevent="handleSave(onSave)"
      >
        {{ $t('saveBtn')}}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormButtons',
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    async handleCancel() {
      this.disabled = true;

      return await this.onCancel().finally(() => {
        this.disabled = false;
      });
    },
    async handleSave() {
      this.disabled = true;

      return await this.onSave().finally(() => {
        this.disabled = false;
      });
    },
  },
  props: {
    onSave: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.form-buttons {
  display: flex;
  justify-content: space-between;
}
.form-button {
  width: 50%;

  .v-btn {
    height: 40px !important;
  }
}
</style>

