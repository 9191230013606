var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.edit)?_c('ContentCard',{attrs:{"contentObject":_vm.getDisplayContent,"onClick":function () { return _vm.edit = true; }}}):_c('div',[_c('EditComponent',{staticClass:"exporo-app-edit-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.label'),"stateCallback":_vm.handleEditState},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var onCancel = ref.onCancel;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.phoneMobile.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.phoneMobile.label'),"error":checkIfExists('errors.naturalUser.phoneMobile') ? niceErrorMessage(errors.naturalUser.phoneMobile) : '',"nextBtnClicked":nextBtnClicked,"inputType":"text","filled":true},model:{value:(naturalUser.phoneMobile),callback:function ($$v) {_vm.$set(naturalUser, "phoneMobile", $$v)},expression:"naturalUser.phoneMobile"}}),_c('EditEmail',{attrs:{"filled":true}}),_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.phone.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.phone.label'),"error":checkIfExists('errors.naturalUser.phone') ? niceErrorMessage(errors.naturalUser.phone) : '',"nextBtnClicked":nextBtnClicked,"inputType":"text","filled":true},model:{value:(naturalUser.phone),callback:function ($$v) {_vm.$set(naturalUser, "phone", $$v)},expression:"naturalUser.phone"}}),_c('FormButtons',{attrs:{"onSave":onSave,"onCancel":onCancel}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }