<template>
  <ContentCard v-if="!edit" :contentObject="getDisplayContent" :onClick="() => edit = true" :loading="loading"/>
  <EditComponent
    v-else
    class="exporo-app-edit-view"
    v-bind:limitedFields="limitedFields"
    v-bind:title="$t('metaData.fields.naturalUser.address.label')"
    v-slot="{ naturalUser, checkIfExists, niceErrorMessage, errors, onSave, onCancel, nextBtnClicked }"
    :stateCallback="handleEditState"
  >
    <InputComponent
      v-model="naturalUser.address.street"
      :label="$t('metaData.fields.naturalUser.address.street.label')"
      :placeholder="$t('metaData.fields.naturalUser.address.street.placeholder')"
      :error="
        checkIfExists('errors.naturalUser.address.street')
          ? niceErrorMessage(errors.naturalUser.address.street)
          : ''
      "
      :nextBtnClicked="nextBtnClicked"
      inputType="text"
      :filled="true"
    />
    <InputComponent
      v-model="naturalUser.address.streetNo"
      :label="$t('metaData.fields.naturalUser.address.streetNo.label')"
      :placeholder="$t('metaData.fields.naturalUser.address.streetNo.label')"
      :error="
        checkIfExists('errors.naturalUser.address.streetNo')
          ? niceErrorMessage(errors.naturalUser.address.streetNo)
          : ''
      "
      :nextBtnClicked="nextBtnClicked"
      inputType="text"
      :filled="true"
    />
    <InputComponent
      v-model="naturalUser.address.streetAdditional"
      :label="$t('metaData.fields.naturalUser.address.streetAdditional.label')"
      :placeholder="$t('metaData.fields.naturalUser.address.streetAdditional.placeholder')"
      :error="
        checkIfExists('errors.naturalUser.address.streetAdditional')
          ? niceErrorMessage(errors.naturalUser.address.streetAdditional)
          : ''
      "
      :nextBtnClicked="nextBtnClicked"
      inputType="text"
      :filled="true"
    />
    <InputComponent
      v-model="naturalUser.address.postalCode"
      :label="$t('metaData.fields.naturalUser.address.postalCode.label')"
      :placeholder="$t('metaData.fields.naturalUser.address.postalCode.placeholder')"
      :error="
        checkIfExists('errors.naturalUser.address.postalCode')
          ? niceErrorMessage(errors.naturalUser.address.postalCode)
          : ''
      "
      :nextBtnClicked="nextBtnClicked"
      inputType="text"
      :filled="true"
    />
    <InputComponent
      v-model="naturalUser.address.city"
      :label="$t('metaData.fields.naturalUser.address.city.label')"
      :placeholder="$t('metaData.fields.naturalUser.address.city.placeholder')"
      :error="
        checkIfExists('errors.naturalUser.address.city') ? niceErrorMessage(errors.naturalUser.address.city) : ''
      "
      :nextBtnClicked="nextBtnClicked"
      inputType="text"
      :filled="true"
    />
    <AutoCompleteComponent
      v-model="naturalUser.address.country"
      :label="$t('metaData.fields.naturalUser.address.country.label')"
      :placeholder="$t('metaData.fields.naturalUser.address.country.label')"
      :optionsList="countryOptions"
      :error="
        checkIfExists('errors.naturalUser.address.country')
          ? niceErrorMessage(errors.naturalUser.address.country)
          : ''
      "
      :nextBtnClicked="nextBtnClicked"
      valueKey="code"
      valueLabel="country"
      :filled="true"
      :clearable="false"
    />
    <FormButtons :onSave="onSave" :onCancel="onCancel"/>
  </EditComponent>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import AutoCompleteComponent from '@/components/reusable/Select/AutoComplete.vue';
import FormButtons from '@/components/ExporoApp/reusable/FormButtons.vue';
import ContentCard from '@/components/ExporoApp/reusable/ContentCard.vue';
import { countryOptions } from '@/enum/countries';

export default {
  name: 'AddressInformation',
  data() {
    return {
      countryOptions: countryOptions().map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      })),
      loading: false,
      edit: false,
    };
  },
  methods: {
    handleEditState(loadingState, errors) {
      this.loading = loadingState;
      if(errors.length === 0 && !this.loading) {
        this.edit = false;
      }
    },
  },
  computed: {
    getDisplayContent() {
      return {
        street: {
          label: this.$t('metaData.fields.naturalUser.address.street.label'),
          value: this.$store.state.naturalUser.address.street,
          placeholder: this.$t('metaData.fields.naturalUser.address.street.noValue'),
        },
        streetNo: {
          label: this.$t('metaData.fields.naturalUser.address.streetNo.label'),
          value: this.$store.state.naturalUser.address.streetNo,
          placeholder: this.$t('metaData.fields.naturalUser.address.streetNo.noValue'),
        },
        streetAdditional: {
          label: this.$t('metaData.fields.naturalUser.address.streetAdditional.label'),
          value: this.$store.state.naturalUser.address.streetAdditional,
        },
        postalCode: {
          label: this.$t('metaData.fields.naturalUser.address.postalCode.label'),
          value: this.$store.state.naturalUser.address.postalCode,
          placeholder: this.$t('metaData.fields.naturalUser.address.postalCode.noValue'),
        },
        city: {
          label: this.$t('metaData.fields.naturalUser.address.city.label'),
          value: this.$store.state.naturalUser.address.city,
          placeholder: this.$t('metaData.fields.naturalUser.address.city.noValue'),
        },
        country: {
          label: this.$t('metaData.fields.naturalUser.address.country.label'),
          value: this.$store.state.naturalUser.address['country']
            ? this.countryOptions.filter(opt => opt.value === this.$store.state.naturalUser.address['country'])[0].text 
            : null,
          placeholder: this.$t('metaData.fields.naturalUser.address.country.noValue'),
        },
      };
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'address',
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContentCard,
    FormButtons,
    EditComponent,
    InputComponent,
    AutoCompleteComponent,
  },
};
</script>

<style scoped lang="scss">
</style>

