var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.edit)?_c('ContentCard',{attrs:{"contentObject":_vm.getDisplayContent,"onClick":function () { return _vm.edit = true; }}}):_c('div',[_c('EditComponent',{staticClass:"exporo-app-edit-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.label'),"stateCallback":_vm.handleEditState},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var onCancel = ref.onCancel;
return [_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.birth.dateOfBirth.label'),"error":checkIfExists('errors.naturalUser.birth.dateOfBirth')
          ? _vm.dobValidation(naturalUser.birth.dateOfBirth) || niceErrorMessage(errors.naturalUser.birth.dateOfBirth)
          : '',"nextBtnClicked":function () {},"placeholder":_vm.$t('metaData.fields.naturalUser.birth.dateOfBirth.placeholder'),"inputType":"date","filled":true},model:{value:(naturalUser.birth.dateOfBirth),callback:function ($$v) {_vm.$set(naturalUser.birth, "dateOfBirth", $$v)},expression:"naturalUser.birth.dateOfBirth"}}),_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.birth.placeOfBirth.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.birth.placeOfBirth.placeholder'),"error":checkIfExists('errors.naturalUser.birth.placeOfBirth')
          ? niceErrorMessage(errors.naturalUser.birth.placeOfBirth)
          : '',"nextBtnClicked":function () {},"inputType":"text","filled":true},model:{value:(naturalUser.birth.placeOfBirth),callback:function ($$v) {_vm.$set(naturalUser.birth, "placeOfBirth", $$v)},expression:"naturalUser.birth.placeOfBirth"}}),_c('AutoCompleteComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.birth.countryOfBirth.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.birth.countryOfBirth.label'),"optionsList":_vm.countryOptions,"error":checkIfExists('errors.naturalUser.birth.countryOfBirth')
          ? niceErrorMessage(errors.naturalUser.birth.countryOfBirth)
          : '',"nextBtnClicked":function () {},"valueKey":"code","valueLabel":"country","filled":true,"clearable":false},model:{value:(naturalUser.birth.countryOfBirth),callback:function ($$v) {_vm.$set(naturalUser.birth, "countryOfBirth", $$v)},expression:"naturalUser.birth.countryOfBirth"}}),_c('FormButtons',{attrs:{"onSave":onSave,"onCancel":onCancel}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }