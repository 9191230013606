<template>
  <ContentCard v-if="!edit" :contentObject="getDisplayContent" :onClick="() => edit = true" />
  <div v-else>
    <EditComponent
      class="exporo-app-edit-view"
      v-bind:limitedFields="limitedFields"
      v-bind:title="$t('metaData.fields.naturalUser.label')"
      v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, onCancel }"
      :stateCallback="handleEditState"
    >
      <InputComponent
        v-model="naturalUser.birth.dateOfBirth"
        :label="$t('metaData.fields.naturalUser.birth.dateOfBirth.label')"
        :error="
          checkIfExists('errors.naturalUser.birth.dateOfBirth')
            ? dobValidation(naturalUser.birth.dateOfBirth) || niceErrorMessage(errors.naturalUser.birth.dateOfBirth)
            : ''
        "
        :nextBtnClicked="() => {}"
        :placeholder="$t('metaData.fields.naturalUser.birth.dateOfBirth.placeholder')"
        inputType="date"
        :filled="true"
      />
      <InputComponent
        v-model="naturalUser.birth.placeOfBirth"
        :label="$t('metaData.fields.naturalUser.birth.placeOfBirth.label')"
        :placeholder="$t('metaData.fields.naturalUser.birth.placeOfBirth.placeholder')"
        :error="
          checkIfExists('errors.naturalUser.birth.placeOfBirth')
            ? niceErrorMessage(errors.naturalUser.birth.placeOfBirth)
            : ''
        "
        :nextBtnClicked="() => {}"
        inputType="text"
        :filled="true"
      />
      <AutoCompleteComponent
        v-model="naturalUser.birth.countryOfBirth"
        :label="$t('metaData.fields.naturalUser.birth.countryOfBirth.label')"
        :placeholder="$t('metaData.fields.naturalUser.birth.countryOfBirth.label')"
        :optionsList="countryOptions"
        :error="
          checkIfExists('errors.naturalUser.birth.countryOfBirth')
            ? niceErrorMessage(errors.naturalUser.birth.countryOfBirth)
            : ''
        "
        :nextBtnClicked="() => {}"
        valueKey="code"
        valueLabel="country"
        :filled="true"
        :clearable="false"
      />
      <FormButtons :onSave="onSave" :onCancel="onCancel" />
    </EditComponent>
  </div>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import AutoCompleteComponent from '@/components/reusable/Select/AutoComplete.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import FormButtons from '@/components/ExporoApp/reusable/FormButtons.vue';
import ContentCard from '@/components/ExporoApp/reusable/ContentCard.vue';
import { countryOptions } from '@/enum/countries';

export default {
  name: 'ContactInfoCard',
  data() {
    return {
      edit: false,
      countryOptions: countryOptions().map((country) => ({
        value: country.code,
        text: country.country,
      })),
    };
  },
  computed: {
    getDisplayContent() {
      return {
        dateOfBirth: {
          label: this.$t('metaData.fields.naturalUser.birth.dateOfBirth.label'),
          value: this.getDateOfBirth,
          placeholder: this.$t('metaData.fields.naturalUser.birth.dateOfBirth.noValue'),
        },
        placeOfBirth: {
          label: this.$t('metaData.fields.naturalUser.birth.placeOfBirth.placeholder'),
          value: this.$store.state.naturalUser.birth.placeOfBirth,
          placeholder: this.$t('metaData.fields.naturalUser.birth.placeOfBirth.noValue'),
        },
        countryOfBirth: {
          label: this.$t('metaData.fields.naturalUser.birth.countryOfBirth.label'),
          value: this.$store.state.naturalUser.birth.countryOfBirth
            ? this.countryOptions.filter(opt => opt.value === this.$store.state.naturalUser.birth.countryOfBirth)[0].text
            : null,
          placeholder: this.$t('metaData.fields.naturalUser.birth.countryOfBirth.noValue'),
        },
      };
    },
    getDateOfBirth() {
      if (!this.$store.state.naturalUser.birth.dateOfBirth) return null;
      const [year, month, day] = this.$store.state.naturalUser.birth.dateOfBirth.split('-');

      return `${day || 'dd'}/${month || 'mm'}/${year || 'yyyy'}`;
    },
  },
  methods: {
    handleEditState(loadingState, errors) {
      this.loading = loadingState;
      if(errors.length === 0 && !this.loading) {
        this.edit = false;
      }
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'address',
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ContentCard,
    FormButtons,
    AutoCompleteComponent,
    EditComponent,
    InputComponent,
  },
};
</script>

<style scoped lang="scss">

</style>

